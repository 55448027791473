/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6b076d3e-a67f-42d9-ad89-4976845e2b74",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_UUVeBRlEV",
    "aws_user_pools_web_client_id": "5tl3b0hkemhhgkmcrrh6qqnbmg",
    "sso_auth_key": "",
    "oauth": {},
    "aws_content_delivery_bucket": "worldpay-qa-eu-west-1-website",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://worldpay-qa.merchant-dispute.com"
};


export default awsmobile;
