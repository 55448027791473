import { Auth } from 'aws-amplify'
import axios from 'axios'

export interface PageOf<T = Record<string, unknown>[]> {
    data: T
    pagination: {
        start: number
        limit: number
        total: number
    }
}

export interface BaseOptions {
    Auth: typeof Auth
    fetch: typeof fetch
    axios: typeof axios
}

type QueryParamMap = Record<string, string | string[] | number | number[]>

export default class BaseService {
    private prefixPath = '/api'
    private _auth: typeof Auth
    protected _fetch: typeof window.fetch
    protected axios: typeof axios
    /** share base url path for the service  */
    protected basePath = '/'

    constructor(init?: Partial<BaseOptions>) {
        this._auth = init?.Auth ?? Auth
        this._fetch = init?.fetch ?? window.fetch.bind(window)
        this.axios = init?.axios ?? axios
    }
    /**
     * @deprecated
     * in favor of the axios property.
     *
     * @param path
     * @param init
     */
    protected async request<T = Record<string, unknown>>(
        path: string,
        init?: RequestInit,
        title?: string,
        returnFullErrorResponse: boolean = false
    ): Promise<T> {
        //@ts-ignore
        return axios({
            url: this.isAbsolute(path) ? path : `${this.basePath}${path}`,
            method: init?.method,
            headers: {
                Authorization: await this.getIdToken(),
                ...init?.headers,
            },
            data: init?.body,
        })
            .then((response) => {
                if (
                    [
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/csv',
                    ].includes(response.data.type)
                ) {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    var a = document.createElement('a')
                    a.href = url
                    a.download = `${title ?? 'Data'}.csv`
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                    return Promise.resolve()
                }
                return response.data
            })
            .catch((error) => {
                try {
                    if (returnFullErrorResponse) {
                        return Promise.reject(error.response)
                    } else {
                        return Promise.reject(
                            error.response?.data?.message ??
                                'An error occurred. Please try again later.'
                        )
                    }
                } catch (e) {
                    return Promise.reject(
                        `An unknown error occurred. Please try again later.`
                    )
                }
            })
    }

    /**
     *
     * @param path
     * @param params
     */
    protected injectParams(path: string, params?: QueryParamMap): string
    protected injectParams(params: QueryParamMap): string
    protected injectParams(
        pathOrParams: string | QueryParamMap,
        params: QueryParamMap = {}
    ): string {
        if (typeof pathOrParams === 'string') {
            return `${pathOrParams}?${this.formatParams(params)}`
        }
        return `?${this.formatParams(pathOrParams)}`
    }

    /** Retrive id token for cognito authenticated user */
    protected getIdToken(): Promise<string> {
        return this._auth
            .currentSession()
            .then((sess) => sess.getIdToken().getJwtToken())
    }
    /** Retrive access token for cognito authenticated user */
    protected getAccessToken(): Promise<string> {
        return this._auth
            .currentSession()
            .then((sess) => sess.getAccessToken().getJwtToken())
    }

    private isAbsolute(path: string) {
        return path.slice(0, 5).includes('http')
    }

    private formatParams(incomingParams: QueryParamMap): string {
        const p = Object.entries(incomingParams).reduce((acc, [key, value]) => {
            /** TODO: Typescript is having an issue with value.map here. The map
             * callback is throwing TypeError 'no overlap in signatures'
             */
            let paramArray: string[][] = []
            if (Array.isArray(value)) {
                value.forEach((i: string | number) => {
                    paramArray.push([`${key}[]`, `${i}`])
                })
            }

            return Array.isArray(value)
                ? [...acc, ...paramArray]
                : [...acc, [key, `${value}`]]
        }, [] as string[][])
        return new URLSearchParams(p).toString()
    }
}
