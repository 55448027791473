import React, { useRef } from 'react'
import { Popover, Box, Tooltip } from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'
import { LoadingIndicator } from 'components'
import idDirectory from './idAttributes'

type OnClose = () => void

export interface PopoverSelectProps {
    anchorEl: HTMLElement | null
    setAnchorEl: (value: HTMLElement | null) => void
    value?: string
    placeholder?: string
    onClose?: OnClose
    required?: boolean
    disabled?: boolean
    className?: string
    testId?: string
    displayType?: string
    dropdownWidth?: string | number | null
    pagination?: React.ReactNode
    searchTextField?: React.ReactNode
    textFieldLabel?: string
    loadingInitialValue?: boolean
    selectFontSize?: string
    enableSelectedValueTooltip?: boolean
    enableHighlightSelectBorder?: boolean
    enablePopoverContainerOnClick?: boolean
}

/**
 * Use SearchableSelect to ___________________
 */
export const PopoverSelect: React.FC<PopoverSelectProps> = ({
    children,
    anchorEl,
    setAnchorEl,
    value = '',
    placeholder = '',
    onClose = () => {},
    required = false,
    disabled = false,
    className = '',
    testId = '',
    displayType = 'outlined',
    dropdownWidth = null,
    pagination,
    searchTextField,
    textFieldLabel = '',
    loadingInitialValue = false,
    selectFontSize = '14px',
    enableSelectedValueTooltip = false,
    enableHighlightSelectBorder = false,
    enablePopoverContainerOnClick = false,
}) => {
    const ref = useRef<HTMLDivElement | null>(null)

    const open = Boolean(anchorEl)
    const minWidth = ref.current?.clientWidth ?? 300
    const width = dropdownWidth ?? ref.current?.clientWidth ?? 300

    return (
        <div
            className={`${'emp-popoverSelect-root'} ${className}`}
            id={`${idDirectory.divRoot}-${testId}`}
            style={{
                fontSize: selectFontSize,
                color: clientTheme.formFields.formText.standard.color,
            }}
            tabIndex={0}
        >
            {textFieldLabel && (
                <label
                    className={'emp-popoverSelect-textFieldLabel'}
                    style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : '' }}
                >
                    {textFieldLabel}
                </label>
            )}
            <Box
                ref={ref}
                className={`${
                    displayType === 'standard'
                        ? 'emp-popoverSelect-displayStandard'
                        : 'emp-popoverSelect-displayOutlined'
                }`}
                style={{
                    ...(enableHighlightSelectBorder &&
                        displayType === 'outlined' && {
                            border: `1px solid ${clientTheme.secondary}`,
                        }),
                }}
                onClick={(e) => {
                    if (disabled) {
                        return
                    }
                    setAnchorEl(e.currentTarget)
                }}
                sx={[
                    displayType === 'standard'
                        ? {
                              borderBottom: `solid 1px ${
                                  disabled ? 'grey' : '#505256'
                              }`,
                              color: disabled ? 'grey' : 'inherit',
                              fontSize: selectFontSize,
                          }
                        : {
                              border: `solid 1px ${
                                  disabled ? '#c5c5c5' : 'rgb(204, 204, 204)'
                              }`,
                              color: disabled ? '#c5c5c5' : 'inherit',
                              borderRadius:
                                  clientTheme.selectionBox.borderRadius,
                              borderBottomRightRadius: open
                                  ? 0
                                  : clientTheme.selectionBox.borderRadius,
                              borderBottomLeftRadius: open
                                  ? 0
                                  : clientTheme.selectionBox.borderRadius,
                              fontSize: selectFontSize,
                          },
                ]}
            >
                {loadingInitialValue ? (
                    <span style={{ marginLeft: 10 }}>
                        <LoadingIndicator />
                    </span>
                ) : (
                    <>
                        {enableSelectedValueTooltip ? (
                            <Tooltip arrow placement="top" title={value}>
                                <span
                                    style={{
                                        color: value
                                            ? clientTheme.formFields.formText
                                                  .standard.color
                                            : 'lightgray',
                                    }}
                                    className="emp-popoverSelect-selectedValueText"
                                >
                                    {`${value || placeholder} ${
                                        required && !value ? '*' : ''
                                    }`}
                                </span>
                            </Tooltip>
                        ) : (
                            <span
                                style={{
                                    color: value
                                        ? clientTheme.formFields.formText
                                              .standard.color
                                        : 'lightgray',
                                }}
                                className="emp-popoverSelect-selectedValueText"
                            >
                                {`${value || placeholder} ${
                                    required && !value ? '*' : ''
                                }`}
                            </span>
                        )}
                        <span style={{ margin: '0px 3px' }}>
                            {Boolean(anchorEl) ? (
                                <Icon
                                    icon={faCaretUp}
                                    className={'emp-popoverSelect-icon'}
                                />
                            ) : (
                                <Icon
                                    icon={faCaretDown}
                                    className={'emp-popoverSelect-icon'}
                                />
                            )}
                        </span>
                    </>
                )}
            </Box>
            <Popover
                open={Boolean(anchorEl) && !loadingInitialValue}
                anchorEl={anchorEl}
                elevation={0}
                onClose={() => {
                    setAnchorEl(null)
                    onClose()
                }}
                classes={{
                    root: 'emp-popoverSelect-popover',
                    paper: 'emp-popoverSelect-popoverPaper',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {searchTextField && (
                    <div className={'emp-popoverSelect-searchTextField'}>
                        {searchTextField}
                    </div>
                )}
                <ul
                    className={'emp-popoverSelect-options'}
                    style={{
                        minWidth: minWidth,
                        width: width,
                        fontSize: selectFontSize,
                        color: clientTheme.formFields.formText.standard.color,
                    }}
                    onClick={() => {
                        if (!enablePopoverContainerOnClick) return
                        setAnchorEl(null)
                        onClose()
                    }}
                >
                    {children}
                </ul>
                <div>{pagination}</div>
            </Popover>
        </div>
    )
}

type SelectOptionClickHandler = () => void

interface SelectOptionProps {
    onClick?: SelectOptionClickHandler
    noHover?: boolean
    testId?: string
}

export const SelectOption: React.FC<SelectOptionProps> = ({
    onClick = () => {},
    noHover = false,
    children,
    testId = '',
}) => {
    return (
        <Box
            component="li"
            sx={{
                '&:hover': {
                    backgroundColor: noHover
                        ? 'inherit'
                        : clientTheme.secondaryLight,
                    color: noHover ? 'inherit' : 'white',
                },
                wordWrap: 'break-word',
            }}
            onClick={onClick}
            {...(testId && { id: testId })}
        >
            {children}
        </Box>
    )
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places
