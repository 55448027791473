import { Storage } from 'aws-amplify'
import { IFilePlusPages } from 'hooks/cb-api'
import BaseService, { BaseOptions } from 'lib/BaseService'
import md5 from 'md5'

interface UploadLink {
    url: string
    bucket: string
    region: string
    s3_key: string
    action: string
    method: string
    enctype: string
    acl: string
    'X-Amz-Security-Token': string
    key: string
    'X-Amz-Credential': string
    'X-Amz-Algorithm': string
    'X-Amz-Date': string
    Policy: string
    'X-Amz-Signature': string
}

interface Options extends BaseOptions {
    Storage: typeof Storage
}

export interface RepresentmentDownload {
    case_id: number
    date_created: string
    link: string
    origin: string
    case_comments: string
}

export class DocumentsService extends BaseService {
    basePath = '/docs'
    storage: typeof Storage

    constructor(init?: Partial<Options>) {
        super(init)
        this.storage = init?.Storage ?? Storage
    }

    representment(id: number) {
        return this.request<RepresentmentDownload>(`/representment/${id}`)
    }

    uploadRepresentment(id: number, files: File[]) {
        return this.request<UploadLink>(
            this.injectParams('/representment/upload-link', { case_id: id })
        )
            .then(async (presigned) => {
                return Promise.resolve([
                    presigned,
                    await Promise.all(
                        files.map((file) => this.putFile(presigned, file))
                    ),
                ])
            })
            .then(([presigned]: any) => {
                return files.map((file) =>
                    this.finalizeRepresentmentUpload(
                        id,
                        presigned.s3_key,
                        files
                    )
                )
            })
    }

    private finalizeRepresentmentUpload(
        id: number,
        key: string,
        files: File[]
    ) {
        return this.request('/representment', {
            method: 'POST',
            body: JSON.stringify({
                case_id: id,
                origin: 2, // type upload
                files: files.map((file) => ({
                    // eslint-disable-next-line
                    s3_key: key.replace('${filename}', file.name),
                    filename: file.name,
                    content_type: file.type,
                    size: file.size,
                })),
            }),
        })
    }

    templates(merchantId: string, options?: any) {
        return this.request(
            this.injectParams('/templates', {
                merchant_id: merchantId,
                ...options,
            })
        )
    }

    templateTypes(id: string) {
        return this.request('/templates/types')
    }

    uploadTemplate(
        merchantId: string,
        file: IFilePlusPages,
        documentTypeId?: number,
        midIds?: number[]
    ) {
        const uploadedFile = file
        const fileExtension = uploadedFile.type === 'image/jpeg' ? 'jpg' : 'pdf'
        const md5FileName = `${md5(uploadedFile.name)}.${fileExtension}`

        return this.request<UploadLink>(
            this.injectParams('/templates/upload-link', {
                merchant_id: merchantId,
            })
        )
            .then((presigned) => this.putFile(presigned, file, md5FileName))
            .then(([presigned]) =>
                this.finalizeTemplateUpload({
                    merchant_id: merchantId,
                    key: presigned.s3_key,
                    file,
                    md_5_file_name: md5FileName,
                    document_type_id: documentTypeId,
                    mid_ids: midIds,
                })
            )
    }

    postRepresentment(id: number, combinedTemplates: any, isQCReview:boolean = false, isReupload:boolean = false, comment:string = '') {
        const filesArray: any[] = []
        const templatesArray: any[] = []

        combinedTemplates.map((doc: any, idx: any) => {
            const order = idx + 1
            switch (doc.type) {
                case 'file':
                    filesArray.push({
                        ...doc.file,
                        order_num: order,
                    })
                    break
                case 'template':
                    templatesArray.push({
                        id: doc.templateId,
                        order_num: order,
                    })
                    break
                default:
                    break
            }

            return true
        })

        const sendBody = {
            case_id: id,
            origin: 2,
            files: filesArray,
            templates: templatesArray,
            qc_review: isQCReview,
            is_reupload: isReupload,
            comment: comment,
        }

        return this.request('/representment', {
            method: 'POST',
            body: JSON.stringify(sendBody),
        })
    }

    uploadSingleFile(caseId: string, file: File) {
        // @ts-ignore
        const uploadedFile = file[0]
        const fileExtension = uploadedFile.type === 'image/jpeg' ? 'jpg' : 'pdf'
        const md5FileName = `${md5(uploadedFile.name)}.${fileExtension}`
        return this.request<UploadLink>(
            this.injectParams('/representment/upload-link', { case_id: caseId })
        )
            .then((presigned) => {
                return this.putFile(presigned, uploadedFile, md5FileName)
            })
            .then(([presigned]) => {
                return {
                    s3_key: presigned.s3_key.replace(
                        // eslint-disable-next-line
                        '${filename}',
                        md5FileName
                    ),
                    filename: uploadedFile.name,
                    content_type: uploadedFile.type,
                    size: uploadedFile.size,
                }
            })
    }

    deleteTemplate(merchantId: string, templateId: string) {
        return this.request(`/merchant/${merchantId}/template/${templateId}`, {
            method: 'DELETE',
        })
    }

    private finalizeTemplateUpload(params: {
        merchant_id: string
        key: string
        file: IFilePlusPages
        md_5_file_name?: string
        document_type_id?: number
        mid_ids?: number[]
    }) {
        const {
            merchant_id,
            key,
            file,
            md_5_file_name,
            document_type_id,
            mid_ids,
        } = params
        let s3_key = ''

        if (md_5_file_name) {
            // eslint-disable-next-line
            s3_key = key.replace('${filename}', md_5_file_name)
        } else {
            // eslint-disable-next-line
            s3_key = key.replace('${filename}', file.name)
        }

        const body = {
            merchant_id,
            // eslint-disable-next-line
            s3_key,
            name: file.name,
            file_name: file.name,
            content_type: file.type,
            size: file.size,
            template_type: 1,
            pages: file.pages ?? 1,
            document_type_id,
            mid_ids,
        }

        return this.request('/templates', {
            method: 'POST',
            body: JSON.stringify(body),
        })
    }

    /**
     *
     * @param presigned
     * @param data
     *
     * @remark
     * The variable ${filename} as a string is automatically replaced with the name of the
     * file provided by the user and is recognized by all form fields.
     *
     */
    private putFile(
        presigned: UploadLink,
        data: File,
        md5FileName?: string
    ): Promise<[UploadLink, Response]> {
        const form = new FormData()
        const {
            action,
            method,
            // destructuring to remove unneeded properties
            s3_key,
            bucket,
            region,
            url,
            enctype,
            // key will be set manually
            key,
            ...requiredFormValues
        } = presigned
        Object.entries(requiredFormValues).forEach(([k, v]) => {
            form.append(k, v as string)
        })
        if (md5FileName) {
            // eslint-disable-next-line
            form.append('key', s3_key.replace('${filename}', md5FileName))
        } else {
            form.append('key', s3_key)
        }

        // file must be last
        form.append('file', data)
        return Promise.all([
            Promise.resolve(presigned),
            this._fetch(action, {
                method,
                body: form,
            }),
        ])
    }
}

export class DocumentsCsvService extends BaseService {
    basePath = ''
    storage: typeof Storage

    constructor(init?: Partial<Options>) {
        super(init)
        this.storage = init?.Storage ?? Storage
    }

    uploadOnboardingCsvFile(file: File) {
        return this.request<UploadLink>(
            this.injectParams('/cm/onboarding/upload')
        )
            .then((presigned) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return this.putFile(presigned, uploadedFile)
            })
            .then(([presigned]) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return {
                    s3_key: presigned.s3_key.replace(
                        // eslint-disable-next-line
                        '${filename}',
                        uploadedFile.name
                    ),
                    filename: uploadedFile.name,
                    content_type: uploadedFile.type,
                    size: uploadedFile.size,
                }
            })
    }

    postOnboardingCsvUpload(params: {
        s3_key: string
        type: string
        merchant_id: string
    }) {
        return this.request('/cm/onboarding', {
            method: 'POST',
            body: JSON.stringify(params),
        })
    }

    /**
     *
     * @param presigned
     * @param data
     *
     * @remark
     * The variable ${filename} as a string is automatically replaced with the name of the
     * file provided by the user and is recognized by all form fields.
     *
     */
    private putFile(
        presigned: UploadLink,
        data: File
    ): Promise<[UploadLink, Response]> {
        const form = new FormData()
        const {
            action,
            method,
            // destructuring to remove unneeded properties
            s3_key,
            bucket,
            region,
            url,
            enctype,
            // key will be set manually
            key,
            ...requiredFormValues
        } = presigned
        Object.entries(requiredFormValues).forEach(([k, v]) => {
            form.append(k, v as string)
        })

        form.append('key', s3_key)

        // file must be last
        form.append('file', data)
        return Promise.all([
            Promise.resolve(presigned),
            this._fetch(action, {
                method,
                body: form,
            }),
        ])
    }
}

export class DocumentsErtService extends BaseService {
    basePath = '/ert'
    storage: typeof Storage

    constructor(init?: Partial<Options>) {
        super(init)
        this.storage = init?.Storage ?? Storage
    }

    uploadBulkErtsCsvFile(file: File) {
        return this.request<UploadLink>(this.injectParams('/erts/upload/link'))
            .then((presigned) => {
                return this.putFile(presigned, file)
            })
            .then(([presigned]) => {
                const s3_key = presigned.s3_key.replace(
                    // eslint-disable-next-line
                    '${filename}',
                    file.name
                )
                return { s3_key }
            })
    }

    postBulkErtsCsvUpload(params: {
        s3_key: string
        format: { index: number | null; type: string }[]
    }) {
        return this.request(
            '/erts/upload',
            {
                method: 'POST',
                body: JSON.stringify(params),
            },
            undefined,
            true
        )
    }

    uploadResolveErtFile(file: File, ertId: number) {
        // @ts-ignore
        const fileExtension = /\.[0-9a-z]+$/.exec(file['name'])[0]
        const md5FileName = `${md5(file['name'])}${fileExtension}`
        return this.request<UploadLink>(
            this.injectParams(`/erts/${ertId}/resolve/upload`)
        )
            .then((presigned) => {
                return this.putFile(presigned, file, md5FileName)
            })
            .then(([presigned]) => {
                const s3_key = presigned.s3_key.replace(
                    // eslint-disable-next-line
                    '${filename}',
                    md5FileName
                )
                return { s3_key }
            })
    }

    postResolveErtUpload(
        params: {
            s3_key: string | null
            result: number
            notes: string
        },
        ertId: number
    ) {
        return this.request(`/erts/${ertId}/resolve`, {
            method: 'POST',
            body: JSON.stringify(params),
        })
    }

    uploadBulkResolveErtsFile(file: File) {
        // @ts-ignore
        const fileExtension = /\.[0-9a-z]+$/.exec(file['name'])[0]
        const md5FileName = `${md5(file['name'])}${fileExtension}`
        return this.request<UploadLink>(
            this.injectParams('/erts/bulk/resolve/upload')
        )
            .then((presigned) => {
                return this.putFile(presigned, file, md5FileName)
            })
            .then(([presigned]) => {
                const s3_key = presigned.s3_key.replace(
                    // eslint-disable-next-line
                    '${filename}',
                    md5FileName
                )
                return { s3_key }
            })
    }

    postBulkResolveErtsUpload(params: {
        s3_key: string | null
        result: number
        notes: string
        erts: number[]
    }) {
        return this.request('/erts/bulk/resolve', {
            method: 'POST',
            body: JSON.stringify(params),
        })
    }

    /**
     *
     * @param presigned
     * @param data
     *
     * @remark
     * The variable ${filename} as a string is automatically replaced with the name of the
     * file provided by the user and is recognized by all form fields.
     *
     */
    private putFile(
        presigned: UploadLink,
        data: File,
        md5FileName?: string
    ): Promise<[UploadLink, Response]> {
        const form = new FormData()
        const {
            action,
            method,
            s3_key,
            bucket,
            region,
            url,
            enctype,
            key,
            ...requiredFormValues
        } = presigned
        Object.entries(requiredFormValues).forEach(([k, v]) => {
            form.append(k, v as string)
        })

        if (md5FileName) {
            // eslint-disable-next-line
            form.append('key', s3_key.replace('${filename}', md5FileName))
        } else {
            form.append('key', s3_key)
        }

        // file must be last
        form.append('file', data)
        return Promise.all([
            Promise.resolve(presigned),
            this._fetch(action, {
                method,
                body: form,
            }),
        ])
    }
}

export class DocumentsCaseUpdaterService extends BaseService {
    basePath = ''
    storage: typeof Storage

    constructor(init?: Partial<Options>) {
        super(init)
        this.storage = init?.Storage ?? Storage
    }

    uploadCsvFile(file: File) {
        return this.request<UploadLink>(
            this.injectParams('/cm/cases/updater/upload')
        )
            .then((presigned) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return this.putFile(presigned, uploadedFile)
            })
            .then(([presigned]) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return {
                    s3_key: presigned.s3_key.replace(
                        // eslint-disable-next-line
                        '${filename}',
                        uploadedFile.name
                    ),
                    filename: uploadedFile.name,
                    content_type: uploadedFile.type,
                    size: uploadedFile.size,
                }
            })
    }

    postCaseUpdater(params: {
        s3_key: string
        size: number
        filename: string
    }) {
        return this.request('/cm/cases/updater', {
            method: 'POST',
            body: JSON.stringify(params),
        })
    }

    /**
     *
     * @param presigned
     * @param data
     *
     * @remark
     * The variable ${filename} as a string is automatically replaced with the name of the
     * file provided by the user and is recognized by all form fields.
     *
     */
    private putFile(
        presigned: UploadLink,
        data: File
    ): Promise<[UploadLink, Response]> {
        const form = new FormData()
        const {
            action,
            method,
            // destructuring to remove unneeded properties
            s3_key,
            bucket,
            region,
            url,
            enctype,
            // key will be set manually
            key,
            ...requiredFormValues
        } = presigned
        Object.entries(requiredFormValues).forEach(([k, v]) => {
            form.append(k, v as string)
        })

        form.append('key', s3_key)

        // file must be last
        form.append('file', data)
        return Promise.all([
            Promise.resolve(presigned),
            this._fetch(action, {
                method,
                body: form,
            }),
        ])
    }
}

export class DocumentsCaseBuilderService extends BaseService {
    basePath = ''
    storage: typeof Storage

    constructor(init?: Partial<Options>) {
        super(init)
        this.storage = init?.Storage ?? Storage
    }

    uploadCaseBuilderFile(file: File, caseId: number) {
        return this.request<UploadLink>(
            //TODO: Change endpoint
            this.injectParams(`/docs/builder/upload-link?case_id=${caseId}`)
        )
            .then((presigned) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return this.putFile(presigned, uploadedFile)
            })
            .then(([presigned]) => {
                // @ts-ignore
                const uploadedFile = file[0]
                return {
                    s3_key: presigned.s3_key.replace(
                        // eslint-disable-next-line
                        '${filename}',
                        uploadedFile.name
                    ),
                    filename: uploadedFile.name,
                    content_type: uploadedFile.type,
                    size: uploadedFile.size,
                }
            })
    }

    //TODO- Setup to post case builder files
    postCaseBuilderFiles(params: {
        s3_key: string
        type: string
    }) {
        return this.request('/cm/onboarding', {
            method: 'POST',
            body: JSON.stringify(params),
        })
    }

    /**
     *
     * @param presigned
     * @param data
     *
     * @remark
     * The variable ${filename} as a string is automatically replaced with the name of the
     * file provided by the user and is recognized by all form fields.
     *
     */
    private putFile(
        presigned: UploadLink,
        data: File
    ): Promise<[UploadLink, Response]> {
        const form = new FormData()
        const {
            action,
            method,
            // destructuring to remove unneeded properties
            s3_key,
            bucket,
            region,
            url,
            enctype,
            // key will be set manually
            key,
            ...requiredFormValues
        } = presigned
        Object.entries(requiredFormValues).forEach(([k, v]) => {
            form.append(k, v as string)
        })

        form.append('key', s3_key)

        // file must be last
        form.append('file', data)
        return Promise.all([
            Promise.resolve(presigned),
            this._fetch(action, {
                method,
                body: form,
            }),
        ])
    }
}
