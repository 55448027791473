/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
    Button,
    ButtonGroup,
    Tooltip,
    Popover,
    CircularProgress,
} from '@mui/material'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import Icon from 'components/Icon'
import { CollapseButtonGroup } from './CollapseButtonGroup'
import { ExtendedColumn } from './ExtendedColumn'
import { CBtools } from 'lib'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

interface IDataGridActionProps {
    className?: string
    tableRef?: React.RefObject<HTMLTableElement> | null
    printId?: string // id of htmlelement to interact with ** Note the tableRef does not work here because it is not an html element - rather a react element - therefore it does not work with html2canvas and throws a type error
    tableData?: object[] // data for csv/excel files
    dataForExportAll?: any
    columns?: ExtendedColumn[]
    hiddenColumns?: string[]
    setHiddenColumns?: (values: any) => void
    onExportAll?: () => void
    onExportAllLoading?: boolean
    onExportXlsx?: () => void
    onExportXlsxLoading?: boolean
    onExportAllXlsx?: () => void
    onExportAllXlsxLoading?: boolean
    onExportCsv?: () => void
    onExportCsvLoading?: boolean
    dataActionPrint?: boolean
    dataActionExportAll?: boolean
    dataActionExcel?: boolean
    dataActionPDF?: boolean
    dataActionExportAllExcel?: boolean
    dataActionCSV?: boolean
    dataActionColumnFilter?: boolean
    dataActionSwitchGrids?: boolean
    enableCurrentGridSwitchOnly?: boolean
    gridName?: string | undefined
    fileName?: string
    testId?: string
}

/**
 * Holds buttons for export, filter and print actions
 */
const DataGridActions: React.FC<IDataGridActionProps> = ({
    hiddenColumns = [],
    columns = [],
    className,
    tableData = [],
    dataForExportAll = [],
    setHiddenColumns = () => {},
    onExportAll = () => {},
    onExportAllLoading = true,
    onExportXlsx = () => {},
    onExportXlsxLoading = true,
    onExportAllXlsx = () => {},
    onExportAllXlsxLoading = true,
    onExportCsv = () => {},
    onExportCsvLoading = true,
    dataActionPrint = true,
    dataActionExportAll = true,
    dataActionExcel = true,
    dataActionPDF = false,
    dataActionExportAllExcel = false,
    dataActionCSV = true,
    dataActionColumnFilter = true,
    dataActionSwitchGrids = false,
    enableCurrentGridSwitchOnly = false,
    gridName,
    fileName = 'Data',
    testId,
    ...props
}) => {
    const history = useHistory()
    const { pathname } = useLocation()
    const { setShowNewDataGrid } = useAuthedUser()
    const [dataActionLoading, setDataActionLoading] = useState({
        copy: false,
        print: false,
        pdf: false,
    })
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const handleOpenColumnFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const closeAlertSnackbar = () => {
        setAlertSnackbarOpen(false)
    }

    const handleCloseColumnFilter = () => {
        setAnchorEl(null)
    }

    const handleColumnFilter = (col: ExtendedColumn) => {
        const columnToHide =
            typeof col.accessor === 'function' ? col.id || '' : col.accessor
        if (hiddenColumns?.includes(columnToHide)) {
            const updatedHiddenColumns = hiddenColumns.filter(
                (i: string) => i !== columnToHide
            )
            setHiddenColumns(updatedHiddenColumns)
            localStorage.setItem(
                `col-filter-${testId}`,
                JSON.stringify(updatedHiddenColumns)
            )
        } else {
            const updatedHiddenColumns = [...hiddenColumns, columnToHide]
            setHiddenColumns(updatedHiddenColumns)
            localStorage.setItem(
                `col-filter-${testId}`,
                JSON.stringify(updatedHiddenColumns)
            )
        }
    }

    const formatTableData = () => {
        let updatedTableData = tableData
        if (gridName === 'reconciliationReport') {
            const valuesTotal = (values: { [key: string]: number }) => {
                return Object.values(values)?.reduce(
                    //@ts-ignore
                    (acc: number, sum: number) => acc + sum,
                    0
                )
            }
            updatedTableData = tableData.map((row: { [key: string]: any }) => {
                return {
                    ...row,
                    retrievals: valuesTotal(row.retrievals),
                    first_cycle: valuesTotal(row.first_cycle),
                    second_cycle: valuesTotal(row.second_cycle),
                }
            })
        }
        return updatedTableData
    }

    const handlePDF = () => {
        setDataActionLoading({
            ...dataActionLoading,
            pdf: true,
        })
        if (!tableData.length) {
            setAlertSnackbarProps({
                title: 'Export PDF',
                message: `No data to export PDF.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            setDataActionLoading({
                ...dataActionLoading,
                pdf: false,
            })
            return
        }

        CBtools.generatePDF(formatTableData(), columns, fileName)
        setDataActionLoading({
            ...dataActionLoading,
            pdf: false,
        })
    }

    const handlePrint = () => {
        setDataActionLoading({
            ...dataActionLoading,
            print: true,
        })
        if (!tableData.length) {
            setAlertSnackbarProps({
                title: 'Print',
                message: `No data to print.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            setDataActionLoading({
                ...dataActionLoading,
                print: false,
            })
            return
        }

        CBtools.print(formatTableData(), columns, '')
        setDataActionLoading({
            ...dataActionLoading,
            print: false,
        })
    }

    const handleCopy = () => {
        setDataActionLoading({
            ...dataActionLoading,
            copy: true,
        })
        if (!tableData.length) {
            setAlertSnackbarProps({
                title: 'Copy to clipboard',
                message: `No data to copy.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            setDataActionLoading({
                ...dataActionLoading,
                copy: false,
            })
            return
        }
        setAlertSnackbarProps({
            title: 'Copy to clipboard',
            message: `Copied ${tableData.length} rows to clipboard.`,
            intent: 'success',
        })
        setAlertSnackbarOpen(true)
        setDataActionLoading({
            ...dataActionLoading,
            copy: false,
        })
    }

    // this is temporary until the backend can send a csv string for all data
    const csvData = React.useMemo(() => {       
        if (!tableData.length) return ''
        
        if (gridName) {
            // This is awful, but needs done as an "emergency". One off hack for users grid CSV export.
            if (gridName === 'midshealth') {
                let CSVString =
                    '"Rating","Gateway ID","MID Group","MID","MID Alias","Processor","Transactions","Chargebacks","CTR Ratio","Ethoca","CDRN","Order Insight","Consumer Clarity","Direct","Alert Count","Alert Trans Ratio","Alert Chargebacks Ration"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.health}",`
                    CSVString += `"${row.gateway_id}",`
                    CSVString += `"${row.mid_group}",`
                    CSVString += `"${row.mid}",`
                    CSVString += `"${row.mid_alias}",`
                    CSVString += `"${row.platform}",`
                    CSVString += `"${row.transactions}",`
                    CSVString += `"${row.chargebacks}",`
                    CSVString += `"${row.ratio}",`
                    CSVString += `"${row.ethoca_count}",`
                    CSVString += `"${row.cdrn_count}",`
                    CSVString += `"${row.order_insight_count}",`
                    CSVString += `"${row.rdr_count}",`
                    CSVString += `"${row.other_count}",`
                    CSVString += `"${row.alert_count}",`
                    CSVString += `"${row.alert_trans_ratio}",`
                    CSVString += `"${row.alert_chargebacks_ratio}",`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'users') {
                let CSVString =
                    '"First Name","Last Name","Email","Username","Merchant","Role","Status"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.fname}",`
                    CSVString += `"${row.lname}",`
                    CSVString += `"${row.email}",`
                    CSVString += `"${row.username}",`
                    CSVString += `"${row.merchant.business_name}",`
                    CSVString += `"${row.role.name}",`
                    CSVString += `"${row.status.name}"`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'merchantSettingsUsers') {
                let CSVString =
                    '"Role","First Name","Last Name","Username","User Status"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.role.name}",`
                    CSVString += `"${row.fname}",`
                    CSVString += `"${row.lname}",`
                    CSVString += `"${row.username}",`
                    CSVString += `"${row.status.name}"`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'loginHistory') {
                let CSVString = '"IP Address","Date","Is Locked"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.ipAddress}",`
                    CSVString += `"${row.date}",`
                    CSVString += `"${row.locked ? 'Yes' : 'No'}"`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'alerts' || gridName === 'preChargebacks') {
                let CSVString =
                    '"Account","Alert ID","Source","Order ID","Trans Amt","Currency","Trans Date","CC Number","CC Type","Descriptor","MID","Alert Date","Outcome"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.account}",`
                    CSVString += `"${row.alert_id}",`
                    CSVString += `"${row.source}",`
                    CSVString += `"${row.order_id}",`
                    CSVString += `"${row.trans_amount}",`
                    CSVString += `"${row.currency}",`
                    CSVString += `"${row.trans_date}",`
                    CSVString += `"${row.cc_num}",`
                    CSVString += `"${row.cc_type}",`
                    CSVString += `"${row.descriptor}",`
                    CSVString += `"${row.mid}",`
                    CSVString += `"${row.alert_date}",`
                    CSVString += `"${row.outcome}"`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'reconciliationReport') {
                let CSVString =
                    '"Flag Comment","Date","Platform","Retrievals","Retrievals Trend","1st Cycle","1st Cycle Trend","2nd Cycle","2nd Cycle Trend","Representments","Total Wins","Win Trend","Reviewer","Actions"\r\n'
                tableData.map((row: any) => {
                    const valuesTotal = (values: { [key: string]: number }) => {
                        return Object.values(values)?.reduce(
                            //@ts-ignore
                            (acc: number, sum: number) => acc + sum,
                            0
                        )
                    }
                    CSVString += `"${row.flag ?? ''}",`
                    CSVString += `"${row.date}",`
                    CSVString += `"${row.platform}",`
                    CSVString += `"${valuesTotal(row.retrievals)}",`
                    CSVString += `"${row.retrievals_trend}%",`
                    CSVString += `"${valuesTotal(row.first_cycle)}",`
                    CSVString += `"${row.first_cycle_trend}%",`
                    CSVString += `"${valuesTotal(row.second_cycle)}",`
                    CSVString += `"${row.second_cycle_trend}%",`
                    CSVString += `"${row.representments}",`
                    CSVString += `"${row.total_wins}",`
                    CSVString += `"${row.win_trend}%",`
                    CSVString += `"${row.reviewer ?? ''}",`
                    CSVString += `"${
                        row.reviewer?.is_reviewed
                            ? 'Marked AS Reviewed'
                            : 'Not Reviewed'
                    }",`

                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'ert') {
                let CSVString =
                    '"ERT ID","Client ID","Client","Status","Level","Content","Created By","Resolved By","Date Added","Date Approved","Date Resolved","Date Archived"\r\n'
                tableData.map((row: any) => {
                    CSVString += `"${row.id}",`
                    CSVString += `"${row.merchant.id}",`
                    CSVString += `"${row.merchant.business_name}",`
                    CSVString += `"${row.status.name}",`
                    CSVString += `"${row.level.name}",`
                    CSVString += `"${row.content}",`
                    CSVString += `"${row.created_by.name}",`
                    CSVString += `"${row.resolved_by ?? ''}",`
                    CSVString += `"${row.date_created ?? ''}",`
                    CSVString += `"${row.date_approved ?? ''}",`
                    CSVString += `"${row.date_resolved ?? ''}",`
                    CSVString += `"${row.date_archived ?? ''}",`
                    return (CSVString += `\r\n`)
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }
            if (gridName === 'caseUpdater') {
                let CSVString =
                    '"ID","Status","Username","Date Uploaded","File"\r\n'
                tableData.forEach((row: any) => {
                    CSVString += `"${row.id}",`
                    CSVString += `"${row.status_name}",`
                    CSVString += `"${row.created_by_user}",`
                    CSVString += `"${row.date_created}",`
                    CSVString += `"${row.loc}",`
                })
                return 'data:text/csv;charset=utf-8,' + CSVString
            }

            // Fallthrough.
            return 'data:text/csv;charset=utf-8, "No data"'
        } else {
            let CSVString = ''
            const JSON_array = tableData.map((row: any, idx) => {
                let rowJSON: any = {}
                columns.forEach((column) => {
                    const key = column.accessor.includes('.')
                        ? column.accessor.split('.')[0]
                        : column.accessor
                    if (typeof row[key] === 'object') {
                        if (column.accessor === 'flag') {
                            rowJSON[key] = row[key]?.comment ?? ''
                        } else if (column.accessor === 'assigned_user') {
                            rowJSON[key] = row[key]?.fname
                                ? `${row[key]?.fname} ${row[key]?.lname}`
                                : row[key] ?? ''
                        } else {
                            rowJSON[key] = row[key]?.name ?? ''
                        }
                    } else {
                        rowJSON[key] = row[key]
                    }
                })
                rowJSON['index'] = idx + 1
                return rowJSON
            })
            let properties = columns
                .map((column) => {
                    const key = column.accessor.includes('.')
                        ? column.accessor.split('.')[0]
                        : column.accessor
                    if (column.accessor === 'flag') {
                        return {
                            dataKey: key,
                            header: 'Flag Comment',
                        }
                    }
                    if (typeof column.Header === 'object') {
                        // @ts-ignore
                        return {
                            dataKey: key,
                            header: column.Header.props.value ?? '',
                        }
                    } else if (typeof column.Header === 'string') {
                        return { dataKey: key, header: column.Header }
                    }
                    return undefined
                })
                .filter(
                    (p) =>
                        p !== undefined &&
                        JSON_array[0][p.dataKey] !== undefined
                )
            properties.map((header) => {
                return (CSVString += `"${header?.header}",`)
            })
            CSVString = CSVString + '\r\n'
            JSON_array.map((row) => {
                let stringToAdd = ''
                properties.map((header) => {
                    if (header?.dataKey) {
                        return (stringToAdd += `"${row[header?.dataKey]}",`)
                    } else {
                        return (stringToAdd += `"",`)
                    }
                })
                return (CSVString += stringToAdd + `\r\n`)
            })           
            return 'data:text/csv;charset=utf-8,' + CSVString
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData])

    const handleSwitchGrids = () => {
        if (pathname.includes('-new')) {
            setShowNewDataGrid && setShowNewDataGrid(false)
            history.push(pathname.slice(0, -4))
        } else {
            setShowNewDataGrid && setShowNewDataGrid(true)
            history.push(`${pathname}-new`)
        }
    }

    const handleCurrentGridSwitchOnly = () => {
        history.push(`${pathname}-new`)
    }

    useEffect(() => {
        const localStorageHiddenColumns = JSON.parse(
            //@ts-ignore
            localStorage.getItem(`col-filter-${testId}`)
        )
        localStorageHiddenColumns && setHiddenColumns(localStorageHiddenColumns)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div
                className={`${'emp-dataGridActions-root'} ${className}`}
                id={`${idDirectory.dataGridActions.divRoot}-${testId}`}
            >
                <CollapseButtonGroup
                    className={`emp-dataGridActions-root`}
                    classes={{
                        grouped: 'emp-dataGridActions-btnGrp',
                    }}
                    style={{
                        border:
                            clientTheme.buttons.textOrOutlinedButton.style
                                .border,
                        fontSize: clientTheme.typography.button2.fontSize,
                    }}
                    size="small"
                    orientation="horizontal"
                    aria-label="data grid action button group"
                    variant="text"
                    ignoreFirst={1}
                >
                    {dataActionSwitchGrids && (
                        <Tooltip
                            title={
                                !pathname.includes('-new')
                                    ? 'Switch to new data grid display.'
                                    : 'Switch back to old data grid display.'
                            }
                        >
                            <Button
                                onClick={
                                    enableCurrentGridSwitchOnly
                                        ? handleCurrentGridSwitchOnly
                                        : handleSwitchGrids
                                }
                                id={`${idDirectory.dataGridActions.btnSwitchGrid}-${testId}`}
                                style={{ color: '#263238' }}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                <div>
                                    <Icon
                                        className={`fa fa-right-left ${'emp-dataGridActions-switchArrowIcon'}`}
                                    />
                                    <Icon className={'fa fa-table'} />
                                </div>
                            </Button>
                        </Tooltip>
                    )}
                    {dataActionColumnFilter && (
                        <Tooltip title="Column Filter">
                            <Button
                                onClick={handleOpenColumnFilter}
                                id={`${idDirectory.dataGridActions.btnColFilter}-${testId}`}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {hiddenColumns.length ? (
                                    <>
                                        <span style={{ marginRight: 3 }}>
                                            •
                                        </span>
                                        Column Filter
                                    </>
                                ) : (
                                    <>Column Filter</>
                                )}
                            </Button>
                        </Tooltip>
                    )}

                    <CopyToClipboard
                        text={csvData.replace(
                            'data:text/csv;charset=utf-8,',
                            ''
                        )}
                        onCopy={handleCopy}
                    >
                        <Tooltip title="Copy">
                            <Button
                                id={`${idDirectory.dataGridActions.btnCopy}-${testId}`}
                                disabled={dataActionLoading.copy}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                                variant={'outlined'}
                            >
                                {dataActionLoading.copy ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '11%',
                                            }}
                                        />
                                        <div>Copy</div>
                                    </div>
                                ) : (
                                    'Copy'
                                )}
                            </Button>
                        </Tooltip>
                    </CopyToClipboard>
                    {dataActionCSV && gridName ? (
                        <Tooltip title="CSV">
                            <Button
                                href={csvData}
                                download={`${fileName}.csv`}
                                id={`${idDirectory.dataGridActions.btnCSV}-${testId}`}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                CSV
                            </Button>
                        </Tooltip>
                    ) : (
                        dataActionCSV && (
                            <Tooltip title="CSV">
                                <Button
                                    onClick={onExportCsv}
                                    id={`${idDirectory.dataGridActions.btnCSV}-${testId}`}
                                    disabled={onExportCsvLoading}
                                    sx={
                                        clientTheme.buttons.textOrOutlinedButton
                                            .style
                                    }
                                >
                                    {onExportCsvLoading ? (
                                        <div
                                            className={
                                                'emp-dataGridActions-circularProgressContainer'
                                            }
                                        >
                                            <CircularProgress
                                                size={22}
                                                color="secondary"
                                                style={{
                                                    position: 'absolute',
                                                    left: '3%',
                                                }}
                                            />
                                            <div>CSV</div>
                                        </div>
                                    ) : (
                                        'CSV'
                                    )}
                                </Button>
                            </Tooltip>
                        )
                    )}
                    {dataActionExcel && (
                        <Tooltip title="Excel">
                            <Button
                                onClick={onExportXlsx}
                                id={`${idDirectory.dataGridActions.btnExcel}-${testId}`}
                                disabled={onExportXlsxLoading}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {onExportXlsxLoading ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '10%',
                                            }}
                                        />
                                        <div>Excel</div>
                                    </div>
                                ) : (
                                    'Excel'
                                )}
                            </Button>
                        </Tooltip>
                    )}
                    {dataActionPDF && (
                        <Tooltip title="Export PDF">
                            <Button
                                onClick={handlePDF}
                                disabled={dataActionLoading.pdf}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {dataActionLoading.pdf ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '3%',
                                            }}
                                        />
                                        <div>PDF</div>
                                    </div>
                                ) : (
                                    'PDF'
                                )}
                            </Button>
                        </Tooltip>
                    )}
                    {dataActionPrint && (
                        <Tooltip title="Print">
                            <Button
                                onClick={handlePrint}
                                id={`${idDirectory.dataGridActions.btnPrint}-${testId}`}
                                disabled={dataActionLoading.print}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {dataActionLoading.print ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '11%',
                                            }}
                                        />
                                        <div>Print</div>
                                    </div>
                                ) : (
                                    'Print'
                                )}
                            </Button>
                        </Tooltip>
                    )}
                    {dataActionExportAll && (
                        <Tooltip title="Export All">
                            <Button
                                onClick={onExportAll}
                                id={`${idDirectory.dataGridActions.btnExportAll}-${testId}`}
                                disabled={onExportAllLoading}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {onExportAllLoading ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '30%',
                                            }}
                                        />
                                        <div>Export All</div>
                                    </div>
                                ) : (
                                    'Export All'
                                )}
                            </Button>
                        </Tooltip>
                    )}
                    {dataActionExportAllExcel && (
                        <Tooltip title="Export All (Excel)">
                            <Button
                                onClick={onExportAllXlsx}
                                id={`${idDirectory.dataGridActions.btnExportAllXlsx}-${testId}`}
                                disabled={onExportAllXlsxLoading}
                                sx={
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style
                                }
                            >
                                {onExportAllXlsxLoading ? (
                                    <div
                                        className={
                                            'emp-dataGridActions-circularProgressContainer'
                                        }
                                    >
                                        <CircularProgress
                                            size={22}
                                            color="secondary"
                                            style={{
                                                position: 'absolute',
                                                left: '35%',
                                            }}
                                        />
                                        <div>Export All (Excel)</div>
                                    </div>
                                ) : (
                                    'Export All (Excel)'
                                )}
                            </Button>
                        </Tooltip>
                    )}
                </CollapseButtonGroup>
                {anchorEl && (
                    <Popover
                        open={true}
                        anchorEl={anchorEl}
                        onClose={handleCloseColumnFilter}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        className={`emp-dataGridActions-popover`}
                    >
                        <ButtonGroup orientation="vertical">
                            {columns.map((i: any) => {
                                const headerText =
                                    typeof i.Header === 'string'
                                        ? i.Header
                                        : i.Header.props.value

                                return i.type !== 'ignore' ? (
                                    <Button
                                        key={i.accessor}
                                        className={`${'emp-dataGridActions-hideBtn'} ${
                                            (hiddenColumns.includes(
                                                i.accessor
                                            ) ||
                                                (i.id &&
                                                    hiddenColumns.includes(
                                                        i.id
                                                    ))) &&
                                            'emp-dataGridActions-hidden'
                                        }`}
                                        style={{
                                            backgroundColor:
                                                hiddenColumns.includes(
                                                    i.accessor
                                                ) ||
                                                (i.id &&
                                                    hiddenColumns.includes(
                                                        i.id
                                                    ))
                                                    ? clientTheme.mainLayout
                                                          .backgroundColor
                                                    : '',
                                        }}
                                        onClick={() => handleColumnFilter(i)}
                                        size="small"
                                        id={`${idDirectory.dataGridActions.btnColumn}-${testId}-${headerText}`}
                                    >
                                        {headerText}
                                    </Button>
                                ) : null
                            })}
                            <Button
                                id={`${idDirectory.dataGridActions.btnColumn}-${testId}-reset`}
                                onClick={() => {
                                    setHiddenColumns([])
                                    localStorage.removeItem(
                                        `col-filter-${testId}`
                                    )
                                }}
                                size="small"
                                className={'emp-dataGridActions-resetBtn'}
                                sx={{
                                    '&:hover': {
                                        backgroundColor:
                                            clientTheme.mainLayout
                                                .backgroundColor,
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                    },
                                }}
                            >
                                Reset
                            </Button>
                        </ButtonGroup>
                    </Popover>
                )}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={closeAlertSnackbar}
                showCloseIcon
                testId={testId}
            />
        </div>
    )
}

export default DataGridActions
